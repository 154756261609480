const questions = [
  {
    q: "How would you describe your skin?",
    a: [
      "Prone to dryness.",
      "Your skin is sensitive, at times red or inflamed.",
      "Your skin is oily, feels congested.",
    ],
  },
  {
    q: "Describe your skin thickness.",
    a: [
      "Thin with small pores.",
      "Medium with some visible pores.",
      "Thick with larger pores.",
    ],
  },
  {
    q: "Do you have a tendency towards wrinkles compared to your own age group?",
    a: [
      "Visible wrinkles.",
      "You have a few, but your complexion is overall even & smooth.",
      "Barely any wrinkles & people say you look young for your age.",
    ],
  },
  {
    q: "How would you describe your complexion?",
    a: [
      "Darker, Olive or tendency towards Grayish Color.",
      "Rosy, Yellowish, Coppery Complexion",
      "Fair, Light, White",
    ],
  },
  {
    q: "How does your skin feel to the touch, before your morning ritual?",
    a: ["Rough & Dry", "Warm & Smooth", "Soft, Smooth & Moist"],
  },
  {
    q: "How would you describe your hair?",
    a: [
      "Thin, Dry, Curly, Frizzy, can experience split ends.",
      "Fine, Straight, Oily, Blonde, Red, Premature Grey or Hair Loss.",
      "Thick, Lustrous, your scalp and hair can become Oily.",
    ],
  },
  {
    q: "Your skin tends to undergo?",
    a: [
      "Dryness, Fine Wrinkles and is prone to Blackheads.",
      "Inflammation, Sensitivity, Redness can be Prone to Acne & Rosacea.",
      "Congestion, Oiliness, Prone to Cystic Acne & Whiteheads.",
    ],
  },
  {
    q: "My personality is best described as:",
    a: [
      "Creative, enthusiastic, dreamy, enjoys constant change and movement.",
      "Ambitious, sharp mind, organized, passionate.",
      "Calm, supportive, compassionate, dislikes change.",
    ],
  },
  {
    q: "Under stress I feel:",
    a: [
      "Fearful, Worried or Anxious.",
      "Angry, Irritated or Aggressive.",
      "Stubborn, Withdrawn with difficulty letting go.",
    ],
  },
  {
    q: "My memory is best described as:",
    a: [
      "Learns quickly, but forgets easily.",
      "Sharp with quick to recall.",
      "Learns slowly, but does not forget easily.",
    ],
  },
  {
    q: "My Sleep quality is:",
    a: [
      "Difficulty falling asleep, light or restless sleep.",
      "Waking up around 2am thinking of your day or what needs to be accomplished but able to go back to sleep.",
      "Deep sleeper with effort to waking in the morning.",
    ],
  },
  {
    q: "My body frame is best described as:",
    a: [
      "Thin, does not gain weight easily, tall or short.",
      "Medium frame, average height, athletic build.",
      "Larger frame, gains weight easily & difficult to go lose.",
    ],
  },
  {
    q: "My body temperature tends to be:",
    a: ["Cool.", "Warm.", "Moderate, can get cold."],
  },
  {
    q: "My face shape is best described as:",
    a: [
      "Small, Thin, Long, Narrow with irregular features.",
      "Medium, Angular, Average in Size.",
      "Large on the round side.",
    ],
  },
  {
    q: "My appetite is:",
    a: [
      "Variable, like to snack.",
      "Strong, irritable if meals are skipped.",
      "Low & steady. Comfortable if meals are skipped.",
    ],
  },
  {
    q: "My digestion is:",
    a: [
      "Irregular, prone to gas or bloating sometimes even constipation.",
      "Strong, prone to heartburn & burning sensation when out of balance.",
      "Slow metabolism, regular, prone to heaviness and lethargy after meals.",
    ],
  },
];

let defaultAnswers = [];
let numberOfQuestions = 0;

questions.forEach((e) => {
  defaultAnswers.push(null);
  ++numberOfQuestions;
});

export { questions, defaultAnswers, numberOfQuestions };
