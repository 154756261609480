import React from "react";
import { CLOSE_DEFAULT_URL, LOGO_URL } from "./constants";

let Header = () => {
  let onClose = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const closeUrl = params.ref || CLOSE_DEFAULT_URL;
    document.location = closeUrl;
  };

  return (
    <div className="flex justify-between content-center p-4">
      <div>
        <img src={LOGO_URL} width="150" />
      </div>
      <div>
        <button
          onClick={onClose}
          className="uppercase bg-yellow-800 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-sm"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Header;
