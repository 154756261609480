import React, { useEffect } from "react";
import { VATA, PITTA, KAPHA, DOSHA_COLORS } from "./constants";
import { VictoryPie } from "victory-pie";

let Results = ({ stats }) => {
  let calculateChartData = (stats) => {
    let chartData = [];
    let chartColors = [];
    if (stats.count.vata > 0) {
      chartData.push({ x: "Vata", y: stats.percent.vata });
      chartColors.push(DOSHA_COLORS[VATA]);
    }
    if (stats.count.pitta > 0) {
      chartData.push({ x: "Pitta", y: stats.percent.pitta });
      chartColors.push(DOSHA_COLORS[PITTA]);
    }
    if (stats.count.kapha > 0) {
      chartData.push({ x: "Kapha", y: stats.percent.kapha });
      chartColors.push(DOSHA_COLORS[KAPHA]);
    }
    return [chartData, chartColors];
  };

  let [chartData, chartColors] = calculateChartData(stats);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className="text-center">
      <h1 className="text-2xl font-bold">Dosha Quiz Results</h1>
      <p className="text-gray-400">
        VPK: {Math.round(stats.percent.vata)} /{" "}
        {Math.round(stats.percent.pitta)} / {Math.round(stats.percent.kapha)}
      </p>
      <VictoryPie
        data={chartData}
        colorScale={chartColors}
        radius={100}
        style={{
          labels: { fontSize: 24 },
        }}
      />
    </div>
  );
};

export default Results;
