import React, { useState } from "react";
import { questions, defaultAnswers, numberOfQuestions } from "./questions";
import { VATA, PITTA, KAPHA } from "./constants";
import Results from "./Results";

let Quiz = () => {
  let [answers, setAnswers] = useState(defaultAnswers);
  let [done, setDone] = useState(false);
  let [showResults, setShowResults] = useState(false);

  let onAnswer = (e) => {
    let q = parseInt(e.target.name);
    let a = parseInt(e.target.value);
    let n = [...answers];
    let d = 0;
    n[q] = a;
    n.forEach((a) => {
      if (a !== null) {
        ++d;
      }
    });
    setDone(numberOfQuestions === d);
    setAnswers(n);
  };

  let onShowResults = () => setShowResults(true);

  let calculateResults = () => {
    let r = [0, 0, 0];
    let c = 0;
    answers.forEach((a) => {
      if (a >= VATA && a <= KAPHA) {
        ++r[a];
        ++c;
      }
    });
    return {
      numberOfQuestions,
      numberOfAnswers: c,
      count: {
        vata: r[VATA],
        pitta: r[PITTA],
        kapha: r[KAPHA],
      },
      percent: {
        vata: (r[VATA] / c) * 100.0,
        pitta: (r[PITTA] / c) * 100.0,
        kapha: (r[KAPHA] / c) * 100.0,
      },
    };
  };

  let renderQuestion = (idx) => {
    let cn = "uppercase text-lg pb-4 font-bold";
    if (answers[idx] !== null) {
      cn += " text-gray-500";
    }
    return <h3 className={cn}>{questions[idx].q}</h3>;
  };

  let renderAnswer = (idx, val) => {
    return (
      <div className="mb-3">
        <label>
          <input type="radio" className="mr-2" name={idx} value={val} />
          {questions[idx].a[val]}
        </label>
      </div>
    );
  };

  let renderQuestions = () => {
    return questions.map((question, idx) => (
      <div className="py-4" key={"QUESTION-" + idx} onChange={onAnswer}>
        {renderQuestion(idx)}
        {renderAnswer(idx, VATA)}
        {renderAnswer(idx, PITTA)}
        {renderAnswer(idx, KAPHA)}
      </div>
    ));
  };

  let renderEnabledButton = () => (
    <div className="text-center">
      <button
        onClick={onShowResults}
        className="uppercase bg-yellow-800 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-sm"
      >
        Get My Dosha
      </button>
    </div>
  );

  let renderDisabledButton = () => (
    <div className="text-center">
      <button
        disabled={true}
        className="cursor-not-allowed uppercase bg-gray-500 text-white font-bold py-2 px-4 rounded-sm"
      >
        Get My Dosha
      </button>
    </div>
  );

  let renderButton = () =>
    done ? renderEnabledButton() : renderDisabledButton();

  let renderQuiz = () => (
    <div>
      <h1 className="text-2xl font-bold">Dosha Quiz</h1>
      {renderQuestions()}
      {renderButton()}
    </div>
  );

  return (
    <div className="p-4">
      {showResults ? <Results stats={calculateResults()} /> : renderQuiz()}
    </div>
  );
};

export default Quiz;
