import Header from "./Header";
import Quiz from "./Quiz";

function App() {
  return (
    <div className="App md:container mx-auto">
      <Header />
      <Quiz />
    </div>
  );
}

export default App;
